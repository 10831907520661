html {
  scroll-behavior: smooth;
  background-color: white;
}

body {
  font-size: 62.5%;
}

.app-border {
  border: 2px solid black;
}

.vert-center-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.transform-carousel-img-shadow {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
}