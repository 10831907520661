#myBtn {
    display: none;
    position: fixed;
    bottom: .5em;
    left: 1.5em;
    z-index: 99;
    opacity: 0.5;
}

@media only screen and (min-width: 1440px) {
    #myBtn {
        opacity: 0;
    }
  }