.pagination-switch {
    border: 1px solid #333;
    border-radius: 15px;
    display: inline-block;
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 .25em;
    background-color: #000000;
    transition: background-color 0.2s linear 0s, border-color 0.2s linear 0s;
}